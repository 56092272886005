import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes the WTFPL License.`}</p>
    </PageDescription>
    <h1>{`DO WHAT THE FUCK YOU WANT TO PUBLIC LICENSE`}</h1>
    <p><em parentName="p">{`Version 2, December 2004`}</em></p>
    <p><em parentName="p">{`Copyright (C) 2004 Sam Hocevar `}{`<`}<a parentName="em" {...{
          "href": "mailto:sam@hocevar.net"
        }}>{`sam@hocevar.net`}</a>{`>`}</em></p>
    <p>{` Everyone is permitted to copy and distribute verbatim or modified
copies of this license document, and changing it is allowed as long
as the name is changed. `}</p>
    <pre><code parentName="pre" {...{}}>{`        DO WHAT THE FUCK YOU WANT TO PUBLIC LICENSE 
`}</code></pre>
    <p>{`   TERMS AND CONDITIONS FOR COPYING, DISTRIBUTION AND MODIFICATION `}</p>
    <ol>
      <li parentName="ol">{`You just DO WHAT THE FUCK YOU WANT TO.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      